<template>
  <div class="login">
    <div class="login-left">
      <div class="content">
        <logo class="logo" />
        <h6>Welcome back</h6>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
        >
          <a-form-item>
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: 'Please input your Password!' }
                  ]
                }
              ]"
              size="large"
              type="password"
              placeholder="Password"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              style="width: 100%"
              type="primary"
              html-type="submit"
              class="login-form-button"
            >Confirm</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <common-right />
  </div>
  <!-- </form> -->
</template>
<script>
import { forgetPasswdReset } from '../../api/user'
export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' })
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async(err, values) => {
        if (!err) {
          const { code, msg } = await forgetPasswdReset({
            ctoken: this.$route.query.token,
            password: values.password
          })
          if (code === 200) {
            this.callNewPage('/login')
          } else {
            this.$message.error(msg)
          }
        }
      })
    }
  }
}
</script>
<style scoped>
@import "../../assets/css/user.css";
p {
  margin: 0;
  padding: 0;
}
</style>
