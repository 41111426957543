var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-left"},[_c('div',{staticClass:"content"},[_c('logo',{staticClass:"logo"}),_c('h6',[_vm._v("Welcome back")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your Password!' }
                ]
              }
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  { required: true, message: 'Please input your Password!' }\n                ]\n              }\n            ]"}],attrs:{"size":"large","type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Confirm")])],1)],1)],1)]),_c('common-right')],1)}
var staticRenderFns = []

export { render, staticRenderFns }